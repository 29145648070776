import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  Icon,
  WithPopup,
  Popup,
} from "@abb/abb-common-ux-react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { RootContext } from "../../contexts/root-context";
import { BASE_URL } from "../../strings";
import { convertUtcToLocalString } from "../../util";
import NotificationExample from "../Notification/notification";
import { getToken } from "../Authentication/get-token";
import "./sld.css";
function SLD() {
  const { setSpinner, startTS } = useContext(RootContext); //setSpinner is used to show the spinner when the page is loading
  setSpinner(true);
  const { isnotify, setisnotify } = useContext(RootContext);
  const [error, setError] = useState(false);
  const [isSpin, setispin] = useState(true);
  const [SLDDetails, setSLDDetails] = useState([]);
  const [relayHealthy, setrelayHealthy] = useState([]);
  const [gatewaylist, setgatewaylist] = useState([]);
  const [relayUnHealthy, setrelayUnHealthy] = useState([]);
  //Hover Popup
  const [isOpen, setIsOpen] = React.useState(false);
  const [isControlledOpen, setIsControlledOpen] = React.useState(false);
  const [closeOnEscape, setCloseOnEscape] = React.useState(true);
  const [trigger, setTrigger] = React.useState("hover");
  const [position, setPosition] = React.useState("bottom center right");
  //For PDF file reading
  const [pdfBase64, setPdfBase64] = useState(null);
  const [pdfNotFound, setPdfNotfound] = useState(false);
  const [currectSubId, setcurrectSubId] = useState(localStorage.getItem("selectedSubstationId"))

  const location = useLocation();

  useEffect(() => {
    fetchSLDDetails();
  }, [startTS, isOpen, isControlledOpen, currectSubId]);

  // useEffect(() => {

  // }, [currectSubId])

  const getSelectedSubId = (localsub) => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");

    const urlSubId =
      "BAY-DS-" +
      pathSegments[pathSegments.length - 1].toString().replace("%20DM", "");
    // alert("url " + urlSubId)
    // alert("localsub " + localsub)

    if (localsub !== urlSubId) {

      setcurrectSubId(urlSubId)

    }
    // alert("current" + currectSubId)
  };
  const fetchSLDDetails = async () => {
    setispin(true);
    getSelectedSubId(currectSubId);
    try {
      const response = await axios.get(
        BASE_URL + `sld-metrics?substationId=${currectSubId}`, getToken()
      );
      let fetchedSLDDetailsInfo = response.data;
      setSLDDetails(fetchedSLDDetailsInfo);

      filterSLDDetails();
      setError(false);
      setispin(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setError(true);
          setispin(false);
        } else if (status >= 500 && status < 600) {
          setError(true);
          setispin(false);
        } else {
          setError(true);
          setispin(false);
        }
      } else {
        setError(true);
        setispin(false);
      }
    } finally {
      fetchSLD();
    }
  };

  const fetchSLD = () => {
    fetch(BASE_URL + `sld-preview?substationId=${currectSubId}.pdf`, getToken())
      .then((response) => {

        if (response.status === 400) {
          console.error("PDF not found for substation ID:", currectSubId);
          setPdfNotfound(true);
          return;
        }
        return response.text();
      })
      .then((data) => {

        setPdfBase64(data);
      })
      .catch((error) => {
        setPdfNotfound(true);
        console.error("Error fetching PDF content:", error);
      });


  }
  const filterSLDDetails = () => {
    const allGateways = SLDDetails?.gatewayList?.data;
    setgatewaylist(allGateways);
    const allHealthyRelays = SLDDetails?.relayList?.filter(
      (item) => item.relayHealth === true
    );
    setrelayHealthy(allHealthyRelays);
    const allUnhealthyRelays = SLDDetails?.relayList?.filter(
      (item) => item.relayHealth === false
    );
    setrelayUnHealthy(allUnhealthyRelays);
  };


  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setisnotify("sld-too-large");
        console.error("File size exceeds 5MB. Please choose a smaller file.");
        return;
      }
      if (file.type !== "application/pdf") {
        setisnotify("sld-invalid-format");
        console.error("Invalid file format. Please choose a PDF file.");
        return;
      }
      const reader = new FileReader();
      const headers = {
        'Content-Type': 'application/pdf',
        'Authorization': getToken().headers.Authorization, // Include the token in the Authorization header
      };

      reader.onload = (event) => {
        const base64 = event.target.result.split(",")[1];
        const url = BASE_URL + `sld-upload?substationId=${currectSubId}.pdf`;
        fetch(url, {
          method: "POST",
          body: base64,
          headers: headers
        })
          .then((response) => {
            if (response.ok) {
              setError(false);
              setisnotify("sld-success");
              fetchSLD();
              fetchSLDDetails();
            } else {
              setisnotify("sld-failure");
              console.error("Failed to upload SLD. Please try again later");
              setError(true);
            }
          })
          .catch((error) => {
            setisnotify("sld-failure");

            setError(true);
          });
      };

      reader.readAsDataURL(file);
    }
  };

  function timeAgo(date) {
    const currentDate = new Date();
    const previousDate = new Date(date);
    // Calculate the difference in milliseconds
    const timeDifference = currentDate - previousDate;
    // Calculate the difference in seconds, minutes, hours, days, and months
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    if (months > 0) {
      return months === 1 ? `${months} month ago` : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? `${days} day ago` : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
    } else {
      return 'Just now';
    }
  }
  const sldLastModified = SLDDetails?.sldLastModified ? " SLD last updated on : "
    + timeAgo(SLDDetails?.sldLastModified) + " " : "";
  return (
    <>
      {isnotify === "sld-success" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isnotify === "sld-failure" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isnotify === "sld-too-large" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isnotify === "sld-invalid-format" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      <div className="sld-container">
        <div class="sld-container-title">
          <p className="sld-chart-title">SINGLE LINE DIAGRAM</p>
          <Link style={{ float: "right", color: "black" }} href='' onClick={() => { window.open("#/sld-preview", "_blank") }} >
            <Icon
              className="open-window"
              name={'abb/' + "open-in-new-window"}
              sizeClass="medium"
            />
          </Link>
        </div>
        <hr />
        <table id="sld-info-table">
          <tr className="sld-top-info">
            <td className="first-col">
              <b>Substation Name : </b>
              <span>{currectSubId}</span>
            </td>
            <td className="second-col">
              {sldLastModified}
              <label id="change-sld-link" for="inputField">
                <Icon name="abb/edit" className="change-sld-link-icon" />
                {pdfNotFound ? 'Upload SLD' : 'Change SLD'}</label>
              <input
                disabled={JSON.parse(localStorage.getItem("USER_INFO"))?.permissions?.uploadSingleLineDiagram === "false"}
                accept=".pdf"
                onChange={handleFileChange}
                type="file"
                id="inputField"
                style={{ display: "none" }}
              />
            </td>
          </tr>

          <tr className="sld-top-info">
            <td className="first-col sld-common-class">
              <span className="sld-captions">
                Substation Communication Status :
              </span>

              <div >
                <span>
                  <span>{SLDDetails?.substationCommunicationStatus?.normal?.length}</span>
                  <Icon name="abb/connect" className="charged" />

                  <WithPopup >
                    <span style={{ cursor: "pointer" }}>
                      {`Normal`}
                      {" "}
                    </span>

                    <Popup


                      trigger={trigger}
                      position={position}
                      closeOnLostFocus={false}

                      closeOnEscape={closeOnEscape}
                      onOpen={() => {
                        setIsOpen(true);
                        setIsControlledOpen(true);
                      }}
                    >{SLDDetails?.substationCommunicationStatus?.normal?.length === 0 ? <div>No gateway available</div> :
                      <div className="gateway-status-popup-container">
                        <span className="sld-captions">
                          Gateway Communication Status :
                        </span>
                        <br />
                        {SLDDetails?.substationCommunicationStatus?.normal?.map((item, index) => (
                          <p>
                            <span className="sld-captions">
                              Gateway ID : {" "}{item?.gatewayId}{"  "} ({item?.relays} relays)
                              {item?.substationCommunicationStatus}
                            </span>
                            <br />
                            <span>
                              Last update on :{" "}
                              {convertUtcToLocalString(item?.sentOn.replace('T', ' ').substring(0, 16))}
                              {" "}
                            </span>
                          </p>
                        ))}
                      </div>
                      }
                    </Popup>

                  </WithPopup>
                </span>&nbsp;&nbsp;
                <span>
                  <span>{SLDDetails?.substationCommunicationStatus?.waitingForConnection?.length}</span>
                  <Icon name="abb/time" className="waiting" />
                  <WithPopup>
                    <span style={{ cursor: "pointer" }}>
                      {`Waiting for connection`}
                    </span>
                    <Popup
                      trigger={trigger}
                      position={position}
                      closeOnLostFocus={false}

                      closeOnEscape={closeOnEscape}
                      onOpen={() => {
                        setIsOpen(true);
                        setIsControlledOpen(true);
                      }}
                    >{SLDDetails?.substationCommunicationStatus?.waitingForConnection?.length === 0 ? <div>No gateway available</div> :
                      <div>
                        <span className="sld-captions">
                          Gateway Communication Status :
                        </span>
                        <br />
                        {SLDDetails?.substationCommunicationStatus?.waitingForConnection?.map((item, index) => (
                          <p>
                            <span className="sld-captions">
                              Gateway ID :{item?.gatewayId}{"  "} ({item?.relays} relays)
                              {item?.substationCommunicationStatus}
                            </span>
                            <br />
                            <span>
                              Last update on :{" "}
                              {convertUtcToLocalString(item?.sentOn.replace('T', ' ').substring(0, 16))}
                              {" "}
                            </span>
                          </p>
                        ))}
                      </div>
                      }
                    </Popup>
                  </WithPopup>
                </span>&nbsp;&nbsp;
                <span>
                  <span>{SLDDetails?.substationCommunicationStatus?.connectionLost?.length}</span>
                  <Icon name="abb/disconnect" className="failed" />
                  <WithPopup>
                    <span style={{ cursor: "pointer" }}>
                      {`Connection lost`}
                    </span>
                    <Popup
                      trigger={trigger}
                      position={position}
                      closeOnLostFocus={false}

                      closeOnEscape={closeOnEscape}
                      onOpen={() => {
                        setIsOpen(true);
                        setIsControlledOpen(true);
                      }}
                    >{SLDDetails?.substationCommunicationStatus?.connectionLost?.length === 0 ? <div>No gateway available</div> :
                      <div>
                        <span className="sld-captions">
                          Gateway Communication Status :
                        </span>
                        <br />
                        {SLDDetails?.substationCommunicationStatus?.connectionLost?.map((item, index) => (
                          <p>
                            <span className="sld-captions">
                              Gateway ID :{item?.gatewayId}{"  "} ({item?.relays} relays)
                              {item?.substationCommunicationStatus}
                            </span>
                            <br />
                            <span>
                              Last update on :{" "}
                              {convertUtcToLocalString(item?.sentOn.replace('T', ' ').substring(0, 16))}
                              {" "}
                            </span>
                          </p>
                        ))}
                      </div>
                      }
                    </Popup>
                  </WithPopup>
                </span>
              </div>
            </td>
            <td className="second-col">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <span className="sld-body">
                  <span className="sld-captions">
                    Active Relay Communication Status :{" "}
                  </span>
                  <div style={{ display: "flex" }}>

                    <div style={{ display: "flex" }}>

                      <span>{SLDDetails?.relayStatus?.Online.length}</span>

                      <span>
                        <WithPopup>
                          <span style={{ cursor: "pointer" }}>
                            <Icon
                              name="abb/check-mark-circle-1"
                              className="charged"
                            />
                            Online
                          </span>
                          <Popup
                            trigger={trigger}
                            position={position}

                            closeOnEscape={closeOnEscape}
                            onOpen={() => {
                              setIsOpen(true);
                              setIsControlledOpen(true);
                            }}
                          >
                            {SLDDetails?.relayStatus?.Online.length > 0 ? <div
                              style={{
                                font: "5px",
                                height: "100px",
                                overflowY: "scroll",
                                scrollbarWidth: "2px",
                              }}
                            >
                              <p className="relay-title">
                                Online Relays ({SLDDetails?.relayStatus?.Online.length}):
                              </p>

                              {SLDDetails?.relayStatus?.Online?.map((item, index) => (
                                <p key={index}>
                                  <span className="relay-id">
                                    Relay ID : {item?.assetName} (GW ID : {item?.gatewayId})
                                  </span>
                                  <br />
                                  <span className="relay-time-update">
                                    Last update on :{" "}
                                    <span>
                                      {convertUtcToLocalString(item?.sentOn?.replace('T', ' ').substring(0, 16))}
                                    </span>{" "}
                                  </span>
                                  <br />
                                </p>
                              ))}
                            </div> : <div  >No active healthy online available</div>
                            }
                          </Popup>
                        </WithPopup>
                      </span>
                      <span>
                        <span>
                          &nbsp;&nbsp;{SLDDetails?.relayStatus?.Offline.length}
                        </span>
                        <span>
                          <WithPopup>
                            <span style={{ cursor: "pointer" }}>
                              <Icon name="abb/error-circle-1" className="failed" />
                              Offline
                            </span>
                            <Popup
                              trigger={trigger}
                              position={position}

                              closeOnEscape={closeOnEscape}
                              onOpen={() => {
                                setIsOpen(true);
                                setIsControlledOpen(true);
                              }}
                            >
                              {SLDDetails?.relayStatus?.Offline.length > 0 ?
                                <div
                                  style={{
                                    font: "5px",
                                    height: "100px",
                                    overflowY: "scroll",
                                    scrollbarWidth: "2px",
                                  }}
                                >
                                  <p className="relay-title">
                                    Offline Relays (
                                    {SLDDetails?.relayStatus?.Offline.length}):
                                  </p>

                                  {SLDDetails?.relayStatus?.Offline?.map((item, index) => (
                                    <p key={index}>
                                      <span className="relay-id">
                                        Relay ID : {item?.assetName} (GW ID : {item?.gatewayId})
                                      </span>
                                      <br />
                                      <span className="relay-time-update">
                                        Last update on :{" "}
                                        <span>
                                          {convertUtcToLocalString(item?.sentOn?.replace(/:\d{2}\.\d+/g, ""))}
                                        </span>{" "}
                                      </span>
                                      <br />
                                    </p>
                                  ))}
                                </div> : <div  >No active offline relays available</div>
                              }
                            </Popup>

                          </WithPopup>
                        </span>
                      </span>
                    </div>
                  </div>
                </span>

              </div>
            </td>
          </tr>
        </table>
        {pdfBase64 ? <iframe
          width={"100%"}
          height={"100%"}
          className="sld-iframe"
          type="application/pdf"
          loading="eager"
          src={`data:application/pdf;base64,${pdfBase64}`}
        />
          : pdfNotFound ? <p>No SLD found. Please upload a file</p> : <p>Loading SLD...</p>}
      </div >
    </>
  );
}
export default SLD;
