import React, { useState, useContext } from "react";
import "./disturbance-events.css";
import { RootContext } from "../../contexts/root-context";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { ERROR_LIST, BASE_URL } from "../../strings";
import { Spinner, Link } from "@abb/abb-common-ux-react";
import { convertUtcToLocalString, convertUtcToLocalStringDR } from "../../util";
import { getToken } from "../Authentication/get-token";

function DisturbanceEvents() {

  const { isnotify, startTS, endTS } = useContext(RootContext); //setSpinner is used to show the spinner when the page is loading
  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    fetchDistEvents();
  }, [startTS, isnotify]);

  const fetchDistEvents = async () => {
    setispin(true);

    try {
      const response = await axios.get(
        BASE_URL +
        `dr-list?startDateTime=${startTS}&endDateTime=${endTS}&site=${localStorage.getItem("selectedSubstationId")}`, getToken()
      );
      let jsonArray = response.data.body;

      const sortedData = [...jsonArray].sort((a, b) => {
        // Convert strings to Date objects for comparison
        const dateA = new Date(a.faultTime);
        const dateB = new Date(b.faultTime);
        return dateA - dateB; // Sort in ascending order
      });

      const groupedData = sortedData.reduce((result, item) => {
        const startDate = item.faultTime;
        if (!result[startDate]) {
          result[startDate] = {
            startDate: startDate,
            Acknowledged: 0,
            Unacknowledged: 0,
          };
        }

        if (item.ack) {
          result[startDate].Acknowledged++;
        } else {
          result[startDate].Unacknowledged++;
        }

        return result;
      }, {});
      //alert(groupedData)

      const groupedArray = Object.values(groupedData);


      const chartData = UniqueXAxisValuesChart(groupedArray)

      // 
      setData(chartData);
      setError(false);
      setispin(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setispin(false);
          setError(true);
        } else if (status >= 500 && status < 600) {
          setispin(false);
          setError(true);
        } else {
          setispin(false);
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    }
  };

  const removeSeconds = (date) => {
    const formattedDate = `${date.getFullYear()} - ${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} `;
    return formattedDate;
  }
  const UniqueXAxisValuesChart = (data) => {
    // Merge uv and pv values for the same dates
    const uniqueData = [];
    const dateValuesMap = new Map();
    data.forEach((item) => {
      const { startDate, Acknowledged, Unacknowledged } = item;

      // 
      if (dateValuesMap.has(startDate)) {
        const existingValues = dateValuesMap.get(startDate);
        dateValuesMap.set(startDate, {
          Acknowledged: existingValues.Acknowledged + Acknowledged,
          Unacknowledged: existingValues.Unacknowledged + Unacknowledged
        });
      } else {
        dateValuesMap.set(startDate, { Acknowledged, Unacknowledged });
        uniqueData.push(item);
      }
    });

    uniqueData.forEach((item) => {
      const { startDate } = item;
      const { Acknowledged, Unacknowledged } = dateValuesMap.get(startDate);
      item.Acknowledged = Acknowledged;
      item.Unacknowledged = Unacknowledged;
    });
    return uniqueData;
  }


  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    let startDateTimeXVal = payload.value;
    // startDateTimeXVal = startDateTimeXVal.replace("T", " ").replace("Z", "").split(".")[0]
    // startDateTimeXVal = startDateTimeXVal.slice(0, -3);

    // Display only first and last ticks
    // if (payload.index === 0 || payload.index === data.length - 1) {
    return (
      <text style={{ fontSize: "8px", fontWeight: "400" }} x={x} y={y} transform={`rotate(-45, ${x + 15}, ${y + 56
        })`} dx={10} dy={16} textAnchor="middle" fill="#666">
        {convertUtcToLocalStringDR(startDateTimeXVal)}
      </text>
    );
    // }

    return null;
  };
  const CustomYAxisTick = (props) => {
    const { x, y, payload } = props;
    // Convert the decimal value to an integer
    const intValue = Math.round(payload.value);

    return (
      <text x={x} y={y} dy={-10} textAnchor="end" fill="#666">

        {intValue}
      </text>
    );
  };
  const CustomTooltip = ({ active, payload, label }) => {


    const dateTime = new Date(label);

    const formattedDateTime = dateTime?.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });

    // 
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          <p>{convertUtcToLocalStringDR(formattedDateTime)}</p>
          <p>{`Acknowledged: ${payload[0].value} `}</p>
          <p>{`Unacknowledged: ${payload[1].value} `}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          sizeClass="small"
        ></Spinner>
      ) : (
        <>
          {error ? (
            <span
              style={{ margin: "auto" }}
              color="dark-grey"
              sizeClass="small"
            >
              {ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link
                style={{ color: "#1f1f1f" }}
                onClick={() => {
                  fetchDistEvents();
                }}
              >
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data.length > 0 ? (
                <>
                  <div className="dist-events">
                    <BarChart

                      barCategoryGap={5}
                      barGap={1}
                      width={400}
                      height={125}
                      data={data}
                      padding={{ top: 0, right: 0, left: 40, bottom: 5 }}
                    >
                      <CartesianGrid stroke={false} />
                      <XAxis
                        id="xdatastartDate"
                        dataKey="startDate"
                        angle={-50}
                        dy={10}
                        tick={<CustomizedAxisTick />}
                      >
                        <Label value="Time" position={{ x: -5, y: 30 }} />
                      </XAxis>
                      <YAxis
                        tickCount={2}
                        dx={0}
                        label={{
                          value: "# Fault Occurence",
                          angle: -90,
                          position: { x: 10, y: -25 },
                        }}
                        tickFormatter={(value) => Math.round(value)}
                        tick={<CustomYAxisTick />}
                      />
                      <Tooltip content={<CustomTooltip />} />
                      {/* <Tooltip /> */}
                      <Legend
                        verticalAlign="top"
                        formatter={(value, entry, index) => (
                          <span className="text-color-class">{value}</span>
                        )}
                      />
                      <Bar dataKey="Acknowledged" barSize={5} fill="#F2AC66" />
                      <Bar dataKey="Unacknowledged" barSize={5} fill="#FF4754" />
                    </BarChart>
                    {/* <span className="">{convertUtcToLocalString(startTS.replace('T', ' ').substring(0, 16))}  - {convertUtcToLocalString(endTS.replace('T', ' ').substring(0, 16))}</span> */}
                  </div>
                </>
              ) : (
                <p className="center-text-error">{ERROR_LIST.NO_EVENTS}</p>
              )}
            </>
          )}
        </>
      )
      }
    </>
  );
}

export default DisturbanceEvents;
