import React, { useEffect, useState, useContext } from "react";
import { LoginScreen, Link, Icon } from "@abb/abb-common-ux-react";
import { useNavigate } from "react-router-dom";
import { RootContext } from "../../contexts/root-context";
import { Auth, Amplify } from "aws-amplify";
import { awsConfig } from "../../components/Authentication/aws-config";
import { BASE_URL, BASE_URL_MFA, MESSAGES } from "../../strings";
import axios from "axios";
import { getToken } from "../../components/Authentication/get-token";
import "./login.css";
import { encryptData } from "../../util";
import { BASE_URL_LOG } from "../../strings";
import { Input } from "@abb/abb-common-ux-react";
import { Button } from "@abb/abb-common-ux-react";

Amplify.configure(awsConfig);

function ABBLogin() {
  const { isnotify } = useContext(RootContext);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [showVerifyMFA, setShowVerifyMFA] = useState(false);
  const [totpCode, settotpCode] = React.useState();
  const [formErrors, setFormErrors] = useState({});
  const [getLoginConfig, setgetLoginConfig] = useState({});
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    localStorage.setItem("error", "");
    redirectToLogin();
  }, []);

  useEffect(() => {
  }, [isError]);

  useEffect(() => {
  }, [showVerifyMFA])

  const handleForgotPasswordClick = () => {
    navigate("../forgot-password");
  };

  const redirectToLogin = () => {
    try {
      if (window.location.pathname !== "/") {
        navigate("../");
      }
    } catch (err) { }
  };

  const showSuccess = () => {
    navigate("./substation-explorer");

  };

  const lawText =
    "This is a private system. Do not attempt to logon unless you are an authorized user. " +
    "Any authorized or unauthorized access and use may be monitored and can result in " +
    "criminal or civil prosecution under applicable law.";

  const [usersstate, setUsersState] = useState({
    username: "",
    password: "",
    error: false,
  });

  const gotSignUp = () => {
    navigate("../signup");
  };

  const goForgotPassword = () => {
    navigate("../change-password");
  };

  const postLoginFetchUserDetails = async (username) => {
    try {
      const response = await axios.get(
        BASE_URL + `rbac-get?id=` + username.trim(),
        getToken()
      );
      const userInfo = response.data;

      userInfo ? localStorage.setItem("USER_INFO", JSON.stringify(userInfo)) : postLoginFetchUserDetails()

    } catch (error) { } finally { }
  };

  const handleLoginSubmit = async (username, password) => {
    localStorage.clear();
    setIsError(false);
    setLoading(true);
    try {
      const signInOptions = {
        username,
        password,
      };
      setgetLoginConfig(signInOptions);
      const user = await Auth.signIn(signInOptions);
      // Check if MFA is required for the user
      const challengeParam = user.challengeName;
      if (challengeParam === "MFA_SETUP") {
        try {
          const mfaSetupURL = BASE_URL_MFA;
          window.location.href = mfaSetupURL;
        } catch (error) {
          console.error("Error during MFA setup:", error);
        }
      } else if (challengeParam === "SOFTWARE_TOKEN_MFA") {
        setLoading(false);
        setShowVerifyMFA(true);
        settotpCode()
        // const totpCode = prompt(
        //   "Enter One--time password code from your authenticator app:"
        // );
        //await verifyMFACode(user, username)
      }

      const encryptedUserName = encryptData(username);
      localStorage.setItem("logged_in_user", encryptedUserName);
      localStorage.setItem("error", "");
      setIsError(false);
      // fetch(BASE_URL_LOG + `login-success?userId=${username}`)
      setUsersState({ ...usersstate, iserror: false });
    } catch (error) {

      setLoading(false);
      setIsError(true);

      switch (error.code) {
        case "UserNotConfirmedException":
          localStorage.setItem("error", "User account not confirmed. Please check your email for verification instructions.");
          break;
        case "NotAuthorizedException":
          localStorage.setItem("error", "Incorrect username or password.");
          break;
        case "PasswordResetRequiredException":
          localStorage.setItem("error", "Password reset required. Please reset your password.");
          break;
        case "LimitExceededException":
          localStorage.setItem("error", "Attempt limit exceeded. Please try again later.");
          break;
        default:
          localStorage.setItem("error", "An unexpected error occurred. Please try again later.");
          break;



        //   fetch(BASE_URL_LOG + `login-failure?userId=${username}`);

      }
      setUsersState({
        ...usersstate,
        iserror: true,
        error: true,
      });
    };
  }
  const verifyMFACode = async () => {
    setIsError(false);
    setLoading(true);
    if (totpCode) {
      const user = await Auth.signIn(getLoginConfig);
      try {
        let confirmSignInResp = await Auth.confirmSignIn(
          user,
          totpCode,
          "SOFTWARE_TOKEN_MFA"
        );
        console.log("confirmSignInResp", confirmSignInResp)
        // Check if MFA challenge is successfully completed before proceeding
        if (confirmSignInResp.username) {
          await postLoginFetchUserDetails(getLoginConfig.username);

          setShowVerifyMFA(false)
          setIsError(false);
          showSuccess();
        }
      } catch (e) {
        if (e.name === 'CodeMismatchException') {
          const message = e.message;
          const truncatedMessage = message.split(':')[0];
          localStorage.setItem("error", truncatedMessage);
        } else {
          console.log('An unexpected error occurred:', e.message);
          localStorage.setItem("error", e.message);

        }
        setIsError(true);

      }
    } else {
      setIsError(true);
      localStorage.setItem("error", "Enter Valid Code");
    }
  }


  const cancelForgotPwd = () => {
    setShowVerifyMFA(false)
    setIsError(false);
    setLoading(false);
    navigate("./");
  }
  const handleIdpLogin = async () => {
    try {
      Auth.federatedSignIn({ provider: "SAML" });
    } catch (err) {
    } finally {
    }
  };

  return (
    <>
      {showVerifyMFA ? (
        <div className="ABB_CommonUX_LoginScreen__root ABB_CommonUX_LoginScreen__light login-container">
          <div className="ABB_CommonUX_LoginScreen__header">
            <div />
            <div className="ABB_CommonUX_LoginScreen__logo" />
          </div>
          <div className="ABB_CommonUX_LoginScreen__contentWrapper">
            <div className="ABB_CommonUX_LoginScreen__content">
              <h1 className="ABB_CommonUX_LoginScreen__productName">Verify your identity</h1>
              <div className="ABB_CommonUX_LoginScreen__form">
                <div className="ABB_CommonUX_LoginScreen__formRow">
                  <div className="ABB_CommonUX_LoginScreen__customContent" style={{ fontSize: "14px", fontWeight: 600 }}>
                    Enter a code from your authenticator app.
                    <div />
                  </div>
                </div>
                <div className="ABB_CommonUX_LoginScreen__formRow" style={{ width: "400px", fontSize: "14px", fontWeight: 500, marginTop: "0px" }}>
                  <div className="ABB_CommonUX_LoginScreen__password">
                    <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                      <Input
                        className="ABB_CommonUX_Input__inputElement"
                        type="normal"
                        name="toptp"
                        id="toptp"
                        value={totpCode}
                        onValueChange={(val) => {
                          setIsSaveEnabled(true)
                          settotpCode(val);
                        }} />
                      {loading && (
                        <p style={{ color: "green" }}>{MESSAGES.SIGN_IN_TRIGGER}</p>
                      )}
                      {isError && (
                        <p style={{ color: "red" }} id="error-msg">
                          <Icon
                            name="abb/warning-circle-1"
                            className="incorrect"
                          ></Icon>
                          {localStorage.getItem("error")}
                        </p>
                      )}
                      <br />
                      <br />
                      <Button
                        text="Login"
                        id="mfa-login"
                        disabled={!isSaveEnabled}
                        shape={"rounded"}
                        sizeClass={"small"}
                        type={"primary-black"}
                        onClick={verifyMFACode}
                      />
                      &nbsp;&nbsp;<Button
                        text="Cancel"
                        id="mfa-cancel"
                        shape={"rounded"}
                        sizeClass={"small"}
                        type={"primary-black"}
                        onClick={cancelForgotPwd}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) :
        (<LoginScreen
          productName="Log in"
          className="login-container"
          footerText={lawText}
          onLogin={(username, password) => {
            setCount(count + 1);
            if (username === "" || password === "") {
              setIsError(true);
              localStorage.setItem("error", "Enter Valid Username and password");
            } else if (username && password) {
              handleLoginSubmit(username, password);
            } else {
              setIsError(true);
              localStorage.setItem("error", "Enter Valid Username and password");
            }
          }}
          customContent={(props, state) => {
            setUsersState(state);
            return (
              <div className="success">
                {loading && (
                  <p style={{ color: "green" }}>{MESSAGES.SIGN_IN_TRIGGER}</p>
                )}
                <div className="success2">
                  {isError && (
                    <p style={{ color: "red" }} id="error-msg">
                      <Icon
                        name="abb/warning-circle-1"
                        className="incorrect"
                      ></Icon>
                      {localStorage.getItem("error")}
                    </p>
                  )}
                </div>
                <div>
                  <br />
                  Don't have an account <Link onClick={gotSignUp}>SignUp</Link>
                  <br />
                </div>
              </div>
            );
          }}
        />)}
    </>
  );

}

export default ABBLogin;
